import { AssetListItem } from '@gain/rpc/app-model'
import { useSplitList } from '@gain/utils/list'
import { useVisibleColumns } from '@gain/utils/table'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import React, { useRef } from 'react'
import { useHistory } from 'react-router-dom'

import Card, { CardHeader } from '../../../common/card/card'
import InfoButton from '../../../common/info-button'
import Table from '../../../common/table'
import ViewMoreButton from '../../../common/view-more-button'
import { generateAssetPagePath } from '../../../routes/utils'
import { columnVisibility, similarCompaniesTableColumns } from './similar-companies-card-hooks'

interface SimilarCompaniesCardProps {
  assetListItems: AssetListItem[]
  showMoreHref?: string
  hideAutomated?: boolean
  nrOfVisibleRows?: number
}

export default function SimilarCompaniesCard({
  assetListItems,
  showMoreHref,
  hideAutomated,
  nrOfVisibleRows = 5,
}: SimilarCompaniesCardProps) {
  const tableRef = useRef<HTMLDivElement>(null)
  const history = useHistory()
  const [initialAssets, additionalAssets] = useSplitList(assetListItems, nrOfVisibleRows)

  const visibleColumns = useVisibleColumns(tableRef, similarCompaniesTableColumns, columnVisibility)

  if (initialAssets.length === 0) {
    return null
  }

  return (
    <Card>
      <CardHeader
        title={
          <Stack
            alignItems={'center'}
            direction={'row'}
            flexWrap={'wrap'}
            gap={1}>
            <Typography
              color={'text.primary'}
              variant={'h5'}
              noWrap>
              Similar companies
            </Typography>

            {!hideAutomated && (
              <InfoButton
                color={'warning'}
                dialogMessage={
                  'This list comprises companies that have related business activities.'
                }
                dialogTitle={'Similar companies'}
                label={'Generative AI'}
                size={'medium'}
              />
            )}
          </Stack>
        }
      />

      <Table
        ref={tableRef}
        amountOfPlaceholders={nrOfVisibleRows}
        columns={visibleColumns}
        onRowClick={(row) => history.push(generateAssetPagePath({ id: row.id, name: row.name }))}
        rows={initialAssets}
      />

      {showMoreHref && (
        <ViewMoreButton
          amount={additionalAssets.length}
          href={showMoreHref}
          variant={'chevron-right'}
        />
      )}
    </Card>
  )
}
