import { useAssetListItem, useLegalEntity } from '@gain/api/app/hooks'
import Head from '@gain/modules/head'
import { Asset, DealListItem } from '@gain/rpc/app-model'
import { ListSort } from '@gain/rpc/list-model'
import { AssetProfileType } from '@gain/rpc/shared-model'
import { listFilter, listSort, serializeListSortArray } from '@gain/rpc/utils'
import { buildAssetFileName } from '@gain/utils/asset'
import { isTruthy } from '@gain/utils/common'
import { useUltimateOwnerUsingAsset } from '@gain/utils/legal-entity'
import Grid from '@mui/material/Grid'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import Card, { CardContent, CardHeader } from '../../../common/card/card'
import Table from '../../../common/table'
import SourcesList from '../../../features/source/source-list'
import { legalEntityShareholdersColumns } from '../../legal-entity/ownership'
import { useAssetPageParams } from '../../utils'
import AssetOwnershipDefinition from '../asset-summary/asset-ownership-card/asset-ownership-definition'
import { useAssetSources } from '../route-asset-hooks'
import { useAssetLegalEntities } from '../use-asset-legal-entities'
import {
  useAcquisitionsAndDivestmentsDeals,
  useAssetDealHistoryDeals,
} from './asset-ownership.hooks'
import CardAssetDeals from './card-asset-deals'
import CardAssetFunding from './card-asset-funding'
import { hasFundingMetrics } from './card-asset-funding/card-asset-funding'
import CardAssetHistory from './card-asset-history'
import CardAssetShareholders from './card-asset-shareholders'
import { useCurrentShareholders } from './card-asset-shareholders/asset-shareholder-util'
import LegalStructuresAndShareholdersCard from './legal-structures-and-shareholders-card/legal-structures-and-shareholders-card'
import ManagementCard from './management-card'
import { useManagersWithInvestors } from './management-card/asset-management-util'

const dealSort: ListSort<DealListItem>[] = [
  listSort('announcementDateYear', 'desc'),
  listSort('announcementDateMonth', 'desc'),
]

interface AssetOwnershipProps {
  asset: Asset
}

export default function AssetOwnership({ asset }: AssetOwnershipProps) {
  const params = useAssetPageParams()
  const assetListItem = useAssetListItem(params.id)
  const sources = useAssetSources(asset?.sources, 'background')
  const assetDealHistoryDeals = useAssetDealHistoryDeals(params.id)
  const acquisitionsAndDivestmentsDeals = useAcquisitionsAndDivestmentsDeals(params.id)

  const managers = useManagersWithInvestors(asset)
  const theme = useTheme()
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'))

  const legalEntities = useAssetLegalEntities(asset?.legalEntities || [])
  const globalUltimateOwner = useUltimateOwnerUsingAsset(asset)
  const { data: legalEntity } = useLegalEntity(
    asset?.legalEntities.find(({ financials }) => financials)?.legalEntityId ?? null
  )
  const shareholders = useCurrentShareholders(asset?.shareholders || [])
  const hasHistory = !!asset?.description?.history
  const hasManagement = !!asset?.managers.length
  const hasShareholders = !!shareholders.data?.length
  const hasFunding = assetListItem.data && hasFundingMetrics(assetListItem.data)
  const hasLegalEntityShareholders =
    asset?.profileType === AssetProfileType.Automated &&
    legalEntity &&
    legalEntity.legalEntityShareholders.length > 0

  const nrOfSmallCards = [
    hasLegalEntityShareholders,
    hasLegalEntityShareholders, // two cards are shown when hasLegalEntityShareholders is true (Ownership and Shareholder)
    hasHistory,
    hasManagement,
    hasShareholders,
    hasFunding,
  ].filter(isTruthy).length

  let maxNrOfInitialShareholders = shareholders.data?.length || 0
  if (hasFunding && isLgUp) {
    maxNrOfInitialShareholders = 5
  }

  return (
    <>
      <Head>
        <title>{asset.generalInfo?.name} - Summary</title>
      </Head>
      <Grid
        spacing={2}
        container>
        {legalEntity && hasLegalEntityShareholders && (
          <>
            <Grid
              lg={6}
              xs={12}
              item>
              <Card fullHeight>
                <CardHeader title={'Ownership'} />
                <CardContent>
                  <AssetOwnershipDefinition
                    asset={asset}
                    globalUltimateOwner={globalUltimateOwner}
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid
              lg={6}
              xs={12}
              item>
              <Card fullHeight>
                <CardHeader title={'Shareholders'} />
                <Table
                  columns={legalEntityShareholdersColumns}
                  rows={legalEntity.legalEntityShareholders}
                />
              </Card>
            </Grid>
          </>
        )}

        <>
          <Grid
            lg={6}
            xs={12}
            item>
            <CardAssetHistory
              history={asset.description?.history || ''}
              ownershipIsVerified={asset.generalInfo?.ownershipIsVerified}
              ownershipType={asset.generalInfo?.ownership}
            />
          </Grid>

          {hasManagement && (
            <Grid
              lg={6}
              xs={12}
              item>
              <ManagementCard
                managers={managers.data}
                fullHeight
              />
            </Grid>
          )}
        </>

        {hasShareholders && (
          <Grid
            lg={nrOfSmallCards % 2 === 1 ? 12 : 6}
            xs={12}
            item>
            <CardAssetShareholders
              asset={asset}
              maxNrOfInitialItems={maxNrOfInitialShareholders}
              shareholders={shareholders.data}
              fullHeight
            />
          </Grid>
        )}

        {assetListItem.data && hasFunding && (
          <Grid
            lg={nrOfSmallCards % 2 === 1 ? 12 : 6}
            xs={12}
            item>
            <CardAssetFunding
              asset={assetListItem.data}
              totalFunding={asset?.generalInfo?.totalFunding}
              totalFundingCurrency={asset?.generalInfo?.totalFundingCurrency}
              fullHeight
            />
          </Grid>
        )}

        {assetDealHistoryDeals.data.items.length > 0 && (
          <Grid
            xs={12}
            item>
            <CardAssetDeals
              deals={assetDealHistoryDeals.data.items}
              exportContainerProps={{
                params: {
                  columns: [],
                  filename: buildAssetFileName(asset, 'deals.xlsx'),
                  filter: [
                    listFilter<DealListItem>(
                      'id',
                      '=',
                      assetDealHistoryDeals.data.items.map((item) => item.id)
                    ),
                  ],
                  sort: serializeListSortArray(dealSort),
                },
              }}
              title={'Platform deal history'}
              disableDealTargetMetrics
            />
          </Grid>
        )}

        {acquisitionsAndDivestmentsDeals.data.length > 0 && (
          <Grid
            xs={12}
            item>
            <CardAssetDeals
              deals={acquisitionsAndDivestmentsDeals.data}
              exportContainerProps={{
                params: {
                  columns: [],
                  filename: buildAssetFileName(asset, 'acquisitions and divestments.xlsx'),
                  filter: [
                    listFilter<DealListItem>(
                      'id',
                      '=',
                      acquisitionsAndDivestmentsDeals.data.map((item) => item.id)
                    ),
                  ],
                  sort: serializeListSortArray(dealSort),
                },
              }}
              title={'Acquisitions and divestments'}
              disableDealMetrics
            />
          </Grid>
        )}

        {legalEntities.data.length > 0 && (
          <Grid
            xs={12}
            item>
            <LegalStructuresAndShareholdersCard legalEntities={legalEntities.data} />
          </Grid>
        )}

        {sources.length > 0 && (
          <Grid
            xs={12}
            item>
            <SourcesList sources={sources} />
          </Grid>
        )}
      </Grid>
    </>
  )
}
