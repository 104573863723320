import Head from '@gain/modules/head'
import { AssetList, Investor, InvestorProfileStrategy } from '@gain/rpc/app-model'
import Grid from '@mui/material/Grid'

import Card, { CardContent, CardHeader } from '../../../common/card/card'
import FteMeasurements from '../../../features/fte-measurements/fte-measurements'
import InvestorDealsCard from './investor-deals-card'
import InvestorFundraisingCard from './investor-fundraising-card'
import InvestorMetricsCard from './investor-metrics-card'
import InvestorOrganisationCard from './investor-organisation-card'
import InvestorPortfolioOwnershipCard from './investor-porfolio-ownership-card'
import InvestorPortfolioRegionCard from './investor-porfolio-region-card'
import InvestorPortfolioSectorCard from './investor-porfolio-sector-card'
import ShareFeedbackAlert from './investor-share-feedback'
import InvestorStrategiesCard from './investor-strategies-card'

interface RouteInvestorProps {
  investor: Investor
  assets: AssetList
  representativesCount: number
  strategies: InvestorProfileStrategy[]
}

export default function RouteSummary({
  investor,
  assets,
  representativesCount,
  strategies,
}: RouteInvestorProps) {
  return (
    <>
      <Head>
        <title>{investor.name} - Summary</title>
      </Head>
      <Grid
        spacing={2}
        container>
        <Grid
          xs={12}
          item>
          <InvestorMetricsCard
            assets={assets}
            investor={investor}
            representativesCount={representativesCount}
          />
        </Grid>
        <Grid
          lg={4}
          xs={12}
          item>
          <InvestorOrganisationCard
            assets={assets}
            investor={investor}
          />
        </Grid>
        <Grid
          lg={8}
          xs={12}
          item>
          <InvestorStrategiesCard
            assets={assets}
            investor={investor}
            strategies={strategies}
          />
        </Grid>
        <Grid
          xs={12}
          item>
          <Grid
            spacing={2}
            container>
            <Grid
              lg={4}
              xs={12}
              item>
              <InvestorFundraisingCard funds={investor.funds} />
            </Grid>
            <Grid
              lg={4}
              xs={12}
              item>
              <InvestorDealsCard investor={investor} />
            </Grid>
            <Grid
              lg={4}
              xs={12}
              item>
              <Card
                sx={{ minHeight: 288 }}
                fullHeight>
                <CardHeader title={'FTEs'} />
                <CardContent sx={{ flexGrow: 1 }}>
                  <FteMeasurements fteMeasurements={investor.fteMeasurements} />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        {assets.counts.filtered !== 0 && (
          <Grid
            xs={12}
            item>
            <Grid
              spacing={2}
              container>
              <Grid
                lg={4}
                xs={12}
                item>
                <InvestorPortfolioRegionCard assets={assets.items} />
              </Grid>
              <Grid
                lg={4}
                xs={12}
                item>
                <InvestorPortfolioSectorCard assets={assets.items} />
              </Grid>
              <Grid
                lg={4}
                xs={12}
                item>
                <InvestorPortfolioOwnershipCard
                  assets={assets.items}
                  investor={investor}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid
          xs={12}
          item>
          <ShareFeedbackAlert
            investorId={investor.id}
            investorName={investor.name}
          />
        </Grid>
      </Grid>
    </>
  )
}
