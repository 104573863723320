import { ShuffleIcon } from '@gain/components/icons'
import { AdvisorDealListItem } from '@gain/rpc/app-model'
import { ListSort } from '@gain/rpc/list-model'
import { listSort, serializeListSort } from '@gain/rpc/utils'
import { isTruthy } from '@gain/utils/common'
import { formatMonthYear } from '@gain/utils/date'
import { useIsXs } from '@gain/utils/responsive'
import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'

import {
  MobileListItem,
  MobileListItemButton,
  MobileListItemIcon,
  MobileListItemValue,
} from '../../../../common/list-mobile'
import Logo from '../../../../common/logo'
import { RowComponentProps } from '../../../../common/virtual-table'
import {
  DEAL_ADVISOR_DEFAULT_FILTERS,
  DEAL_ADVISOR_FILTER_MAP,
  DEAL_ADVISOR_FILTER_MENU,
  DealAdvisorFilterField,
} from '../../../../features/deal/deal-filter-bar'
import { ExportListButton } from '../../../../features/export/export-button'
import ListView, {
  dealAdvisorTableColumns,
  DealNameMobileListItemText,
  ListViewApi,
  ListViewProps,
} from '../../../../features/list-view'
import { generateDealPagePath } from '../../../utils'

interface ListViewAdvisorDealsProps
  extends Pick<
    ListViewProps<
      'data.listAdvisorDeals',
      AdvisorDealListItem,
      AdvisorDealListItem,
      DealAdvisorFilterField
    >,
    'defaultFilter' | 'apiRef'
  > {
  advisorName?: string | null
}

export const LIST_VIEW_DEAL_DEFAULT_SORT: ListSort<AdvisorDealListItem>[] = [
  listSort('announcementDate', 'desc'),
  listSort('publicationDate', 'desc'),
]

export default function ListViewAdvisorDeals({
  defaultFilter,
  apiRef,
  advisorName,
}: ListViewAdvisorDealsProps) {
  const isXs = useIsXs()

  const renderPageActions = useCallback(
    (
      api: ListViewApi<
        'data.listAdvisorDeals',
        AdvisorDealListItem,
        AdvisorDealListItem,
        DealAdvisorFilterField
      >
    ) =>
      [
        !isXs && (
          <ExportListButton
            method={'data.exportAdvisorDeals'}
            params={{
              columns: [],
              filename: `${advisorName || 'Advisor'} deals - Gain.pro.xlsx`,
              filter: api.queryParams.filter,
              search: api.search || '',
              sort: api.sort.map(serializeListSort),
            }}
            tooltipMode={'always'}
            variant={'text'}
          />
        ),
      ].filter(isTruthy),
    [advisorName, isXs]
  )

  return (
    <ListView
      addFilterMenu={DEAL_ADVISOR_FILTER_MENU}
      apiRef={apiRef}
      defaultFilter={defaultFilter}
      defaultFilterModel={DEAL_ADVISOR_DEFAULT_FILTERS}
      defaultSort={LIST_VIEW_DEAL_DEFAULT_SORT}
      filterBarSearchLabel={'Target name'}
      filterBarSearchPlaceholder={'E.g. Coolblue'}
      filterConfigMap={DEAL_ADVISOR_FILTER_MAP}
      inline={isXs}
      method={'data.listAdvisorDeals'}
      renderPageActions={renderPageActions}
      sm={{
        variant: 'virtual-table',
        VariantProps: {
          columns: dealAdvisorTableColumns,
          RowComponentProps: ({ row }) =>
            ({
              component: Link,
              to: generateDealPagePath({
                id: row.id,
                name: row.asset || undefined,
              }),
            } as RowComponentProps),
        },
      }}
      xs={{
        variant: 'list',
        VariantProps: {
          headerProps: {
            title: 'Name',
            secondaryTitle: 'Date',
          },
          renderListItem: (item) => (
            <MobileListItem
              key={item.id}
              disableDivider>
              <MobileListItemButton
                as={Link}
                {...{
                  to: generateDealPagePath({
                    id: item.id,
                    name: item.asset || undefined,
                  }),
                }}>
                <MobileListItemIcon>
                  <Logo
                    defaultIcon={<ShuffleIcon />}
                    region={item.region}
                    size={40}
                    src={item.assetLogoFileUrl}
                  />
                </MobileListItemIcon>
                <DealNameMobileListItemText deal={item} />
                <MobileListItemValue>
                  {formatMonthYear(item.announcementDateMonth, item.announcementDateYear)}
                </MobileListItemValue>
              </MobileListItemButton>
            </MobileListItem>
          ),
        },
      }}
    />
  )
}
